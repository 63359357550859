<template>
<v-card elevation="0" class="pa-2 mx-10" min-height="420" max-height="420">
    <v-row>
        <v-col >
            <v-card-title>TEMPLATE PACK SETTINGS</v-card-title>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="0" sm="0" md="0">
            <v-card
                    min-width="550"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-2">Template Pack Selected: {{this.$store.state.templatePack.packSelected}}</div>
                <v-row>
                    <v-col>
                        <v-card max-width="450"
                                elevation="0">
                            <v-combobox
                                    v-model="packSelected"
                                    :items="packRef"
                                    label="Pack Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                            >
                            </v-combobox>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="doApply"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="0" sm="0" md="0" class="pl-3" v-if="!pIsEnvSelectOnly">
            <v-card
                    min-width="450"
                    max-width="450"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-0">Add Template Pack</div>
                <v-row>
                    <v-col class="pt-1">
                        <v-card max-width="450"
                                elevation="0">
                            <v-text-field
                                    label="Pack Name"
                                    v-model="addPackName"
                            />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-btn
                                @click="doAddPack"
                        >
                            Add
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="0" sm="0" md="0" class="pl-0 pt-3" v-if="!pIsEnvSelectOnly">
            <v-card
                    min-height="130"
                    max-height="130"
                    class="pa-4 my-0"
                    elevation="0"
                    outlined
            >
                <div class="font-weight-black pb-2">Delete Template Pack</div>
                <v-row>
                    <v-col>
                        <v-card min-width="260"
                                max-width="260"
                                elevation="0">
                            <v-combobox
                                    v-model="packDelete"
                                    :items="packRef"
                                    label="Pack Name"
                                    prepend-icon="mdi-filter-variant"
                                    solo
                            >
                            </v-combobox>
                        </v-card>
                    </v-col>
                    <v-col class="pt-5">
                        <v-btn
                                @click="doDelete"
                        >
                            Delete {{packDelete}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <v-overlay :value="isLoading">
        <v-progress-circular
                indeterminate
                size="64"
        ></v-progress-circular>
    </v-overlay>

    <PopupMessage v-if="messageAlert!==''"
                  :pColor="messageColor"
                  @onClose="messageAlert=''"
    >
        {{messageAlert}}
    </PopupMessage>
</v-card>
</template>

<script>

    import PopupMessage from "@/item-editor/modules/PopupMessage/PopupMessage";
    import {GlobalEvents, EVENT_UPDATED_TEMPLATE_PACK_CONFIG} from "@/item-editor/events/GlobalEvents";
    import UnityServiceApi from "@/unity-service-dashboard/api/UnityServiceApi";

    export default {
        name: "UnityTemplatePackConfig",
        props: {
            'pIsEnvSelectOnly': {
                type: Boolean,
                required: false
            },
        },
        components: {
            PopupMessage
        },
        data() {
            return {
                isLoading: false,
                addPackName: '',
                packSelected: '',
                packRef: [],
                messageAlert: '',
                messageColor: 'orange',
                packDelete: '',
            }
        },
        created() {
            this.initPackRef();
        },
        methods: {
            initPackRef(){
                this.packSelected = this.$store.state.templatePack.packSelected;
                let envList = this.$store.state.templatePack.packList;
                this.packRef = [];
                envList.forEach(pack => {
                    this.packRef.push(pack);
                });
                this.packDelete = envList[0].name;
            },
            applyIdToSave(){
                this.$store.state.templatePack.packSelected = this.packSelected;
            },
            doApply(){
                if(this.packSelected === ''){
                    this.setAlertMessage('pack name invalid!', true);
                } else {
                    this.applyIdToSave();
                    this.pushConfig();
                    this.setAlertMessage('Saving is success!', false);
                }
            },
            doAddPack(){
                if(this.addPackName === ''){
                    this.setAlertMessage('pack name is require!', true);
                } else {
                    let envList = this.$store.state.templatePack.packList;
                    let ref = envList.find(r => r === this.addPackName);
                    if(ref){
                        this.setAlertMessage('this pack name adding is exiting!', true);
                    } else {
                        envList.push(this.addPackName);
                        this.packRef.push(this.addPackName);
                        this.addPackName = '';
                        this.pushConfig();
                        this.setAlertMessage('add pack name is success!', false);
                    }
                }
            },
            pushConfig(){
                this.$store.commit(this.$store.state.commitActions.PUSH_TEMPLATEPACK);
                GlobalEvents.$emit(EVENT_UPDATED_TEMPLATE_PACK_CONFIG);
            },
            doDelete(){
                if(this.packDelete === ''){
                    this.setAlertMessage('pack name invalid!', true);
                } else if(this.packDelete === this.packSelected || this.packDelete === this.$store.state.templatePack.packSelected){
                    this.setAlertMessage('pack name is was selected!', true);
                } else {
                    if (confirm(`Are you sure you want to delete ${this.packDelete} Pack, which will remove Automate items and Require Simulator data?`)){
                        this.isLoading = true;
                        UnityServiceApi.deleteTemplatePack(this.packDelete)
                            .then(result => {
                                let isSuccess = result.data.status === 200;
                                this.setAlertMessage(result.data.message, !isSuccess);
                                if(isSuccess){
                                    this.$store.state.templatePack.packList = result.data.packList;
                                    this.initPackRef();
                                    GlobalEvents.$emit(EVENT_UPDATED_TEMPLATE_PACK_CONFIG);
                                }
                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 200);
                            })
                            .catch(error => {
                                this.setAlertMessage('Failed to add delete: '+error, true);
                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 200);
                            });
                    }
                }
            },

            setAlertMessage(msg, isError){
                this.messageAlert = msg;
                if(isError){
                    this.messageColor = "red";
                } else {
                    this.messageColor = "success";
                }
            },
        }
    }
</script>

<style scoped>

</style>